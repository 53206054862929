
import { Component, Vue } from 'vue-property-decorator';
import { getCookie } from '@hokify/shared-components/lib/helpers/cookie';
import { storeLinkHelper } from '~/helpers/store-links';

@Component({
	name: 'FooterApps'
})
export default class FooterApps extends Vue {
	storeLink(store: string): string {
		const cookies = process.client && window.document.cookie;
		const utm = (cookies && getCookie('utm', cookies)) || undefined;
		return storeLinkHelper(store, utm);
	}
}
